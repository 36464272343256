<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{$route.name}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" to="/orders" small v-if="$route.path!='/ppicprod'">
                    <v-icon>mdi-plus</v-icon>
                    Create
                </v-btn>

            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="viewItem(item.order_batch)" class="mr-2">
                    mdi-eye
                </v-icon>
                <v-icon small class="mr-2" @click="editItem(item.order_batch)">
                    mdi-pencil
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
            <template v-slot:[`item.production_period`]="{ item }">
              {{item.minDateProd | moment}} - {{item.maxDateProd | moment}}
            </template>            
            <template v-slot:[`item.delivery_period`]="{ item }">
              {{item.minDateDeliv | moment}} - {{item.maxDateDeliv | moment}}
            </template>            

        </v-data-table>
    </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data: () => ({
      headers: [
        { text: 'Batch', value: 'order_batch' },
        { text: 'Periode Produksi', value: 'production_period' },
        { text: 'Preiode Kirim', value: 'delivery_period' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize()
    },
    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        }
    },

    methods: {
      async initialize () {
        let response = await this.$store.dispatch('getObjs', {apidomain:'orders', parameter:''})
        var tRequestProd = []
        if (response.data.status=='success') tRequestProd = response.data.data
        else return console.log(response)
        const batches = [...new Set(tRequestProd.map(item => item.order_batch))];
        var requestProd = []
        batches.forEach(el => {
            var minDateProd='9999-99-99';
            var maxDateProd='0000-00-00';
            var minDateDeliv='';
            var maxDateDeliv='';
            tRequestProd.forEach(req => {
                if (el==req.order_batch && req.production_date<minDateProd){
                    minDateProd = req.production_date
                    minDateDeliv = req.delivery_date
                }
                if (el==req.order_batch && req.production_date>maxDateProd){
                    maxDateProd = req.production_date
                    maxDateDeliv = req.delivery_date
                }
            })
            requestProd.push({
                order_batch: el,
                minDateProd,
                maxDateProd,
                minDateDeliv,
                maxDateDeliv 
            })
        })
        this.items = requestProd
        console.log(requestProd)
      },

      editItem(batch) {
        if (this.$route.path=='/ppicprod')
          this.$router.push('/ppic/' + batch)
        else
          this.$router.push('orders/' + batch + '/edit')
      },

      viewItem(batch) {
        if (this.$route.path=='/ppicprod')
          this.$router.push('/ppic/' + batch)
        else
          this.$router.push('/orders/' + batch + '/view')
      }

    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>